/** @jsx jsx */
import { jsx } from "theme-ui"

function SvgComponent(props) {
  return (
    <svg width={119} height={38} viewBox="0 0 119 38" fill="none" {...props}>
      <path d="M0 6h100v32H0z" sx={{ fill: "primary" }} />
      <rect
        x={89.5}
        y={0.5}
        width={29}
        height={14}
        rx={1.5}
        sx={{
          fill: "textReverse",
          stroke: "primary",
        }}
      />
      <path
        d="M19.26 21.88c0 1.347-.3 2.56-.9 3.64-.6 1.08-1.46 1.933-2.58 2.56-1.107.613-2.4.92-3.88.92H6V14.84h5.9c1.48 0 2.773.3 3.88.9 1.12.6 1.98 1.433 2.58 2.5.6 1.067.9 2.28.9 3.64zm-7.76 3.16c1 0 1.793-.273 2.38-.82.587-.547.88-1.327.88-2.34s-.293-1.793-.88-2.34c-.587-.547-1.38-.82-2.38-.82h-1.06v6.32h1.06zm9.143-10.4c0-.64.234-1.167.7-1.58.467-.427 1.087-.64 1.86-.64.76 0 1.374.213 1.84.64.467.413.7.94.7 1.58 0 .627-.233 1.147-.7 1.56-.466.413-1.08.62-1.84.62-.773 0-1.393-.207-1.86-.62a1.995 1.995 0 01-.7-1.56zm4.76 3.08V29h-4.44V17.72h4.44zm17.166-.08c1.44 0 2.553.433 3.34 1.3.8.867 1.2 2.04 1.2 3.52V29h-4.42v-5.98c0-.48-.147-.853-.44-1.12-.28-.28-.66-.42-1.14-.42-.494 0-.88.14-1.16.42-.28.267-.42.64-.42 1.12V29h-4.42v-5.98c0-.48-.147-.853-.44-1.12-.28-.28-.66-.42-1.14-.42-.494 0-.88.14-1.16.42-.28.267-.42.64-.42 1.12V29h-4.44V17.72h4.44v1.52a3.54 3.54 0 011.3-1.16c.56-.293 1.213-.44 1.96-.44.813 0 1.533.18 2.16.54a3.9 3.9 0 011.5 1.48 4.965 4.965 0 011.58-1.44 4.021 4.021 0 012.12-.58zm10.623-.06c.733 0 1.367.153 1.9.46.533.293.94.7 1.22 1.22v-1.54h4.42V29h-4.42v-1.54c-.28.52-.687.933-1.22 1.24-.533.293-1.167.44-1.9.44-.88 0-1.673-.227-2.38-.68-.707-.467-1.267-1.133-1.68-2-.4-.88-.6-1.913-.6-3.1s.2-2.213.6-3.08c.413-.88.973-1.547 1.68-2 .707-.467 1.5-.7 2.38-.7zm1.48 3.88c-.48 0-.873.167-1.18.5-.307.333-.46.8-.46 1.4 0 .6.153 1.067.46 1.4.307.333.7.5 1.18.5s.873-.167 1.18-.5c.307-.333.46-.8.46-1.4 0-.6-.153-1.067-.46-1.4-.307-.333-.7-.5-1.18-.5zm20.732 5.4h-4.72l-.7 2.14h-4.66l5.18-14.16h5.12L80.784 29h-4.68l-.7-2.14zm-1.08-3.34l-1.28-3.94-1.28 3.94h2.56zm15.469-5.88c1.293 0 2.3.433 3.02 1.3.733.867 1.1 2.04 1.1 3.52V29h-4.42v-5.98c0-.533-.147-.953-.44-1.26-.28-.32-.66-.48-1.14-.48-.493 0-.88.16-1.16.48-.28.307-.42.727-.42 1.26V29h-4.44V17.72h4.44v1.72a4.035 4.035 0 011.38-1.3c.587-.333 1.28-.5 2.08-.5z"
        sx={{
          fill: "textReverse",
        }}
      />
      <path
        d="M96.88 9.32V11h-.81c-.687 0-1.223-.17-1.61-.51-.387-.34-.58-.903-.58-1.69V7.03h-.72V5.39h.72V4.03h1.97v1.36h1.01v1.64h-1.01v1.81c0 .173.037.297.11.37.08.073.21.11.39.11h.53zm4.628-4c.447 0 .85.117 1.21.35.36.227.644.557.85.99.207.433.31.943.31 1.53 0 .587-.103 1.1-.31 1.54a2.37 2.37 0 01-.85 1c-.36.227-.763.34-1.21.34-.393 0-.733-.083-1.02-.25-.286-.173-.5-.41-.64-.71V11h-1.97V3.6h1.97v2.68c.14-.3.354-.533.64-.7.287-.173.627-.26 1.02-.26zm-.65 1.72a.959.959 0 00-.73.31c-.193.207-.29.487-.29.84 0 .36.097.643.29.85.194.207.437.31.73.31.307 0 .554-.1.74-.3.194-.207.29-.493.29-.86 0-.36-.096-.64-.29-.84-.186-.207-.433-.31-.74-.31zm7.657-1.7c.647 0 1.154.217 1.52.65.367.427.55 1.01.55 1.75V11h-1.95V7.99c0-.327-.086-.58-.26-.76-.166-.187-.393-.28-.68-.28-.293 0-.526.097-.7.29-.173.187-.26.447-.26.78V11h-1.97V5.39h1.97v.94c.16-.3.394-.54.7-.72.307-.18.667-.27 1.08-.27zm6.556 3.98V11h-.81c-.687 0-1.224-.17-1.61-.51-.387-.34-.58-.903-.58-1.69V7.03h-.72V5.39h.72V4.03h1.97v1.36h1.01v1.64h-1.01v1.81c0 .173.036.297.11.37.08.073.21.11.39.11h.53z"
        sx={{
          fill: "primary",
        }}
      />
    </svg>
  )
}

export default SvgComponent
